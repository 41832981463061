import React, { MouseEventHandler, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { useApiContext } from "~/api"
import { VideoWrapper } from "~/components/VideoWrapper/VideoWrapper"
import { useWindowSize } from "~/components/WebGL/useWindowResize"
import { useMediaQuery } from "~/helpers/useMediaQuery"

import kvFade from "./assets/kv_fade.png"
import kvMask from "./assets/kv_mask.png"
import imgPreloader from "./assets/preloader.svg"
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import video from "./assets/video.mp4"
import styles from "./index.module.css"

export const KeyVisual: React.FC = () => {
  const { t } = useTranslation()
  const assets = useApiContext("/assets/list")
  const { width } = useWindowSize()
  const { mdmin } = useMediaQuery()
  const [coordsState, setCoordsState] = useState<string[]>([])
  const [hoverState, setHoverState] = useState<boolean>(false)
  const [endedState, setEndedState] = useState<boolean>(false)
  const [playedState, setPlayedState] = useState<boolean>(false)
  const videoElement = useRef<HTMLVideoElement>(null)
  const preloaderElement = useRef<HTMLDivElement>(null)

  // const industries = assets.reduce((prev, current) => {
  //   if (!prev.includes(current.industry.name)) {
  //     prev.push(current.industry.name)
  //   }
  //
  //   return prev
  // }, [] as string[])

  const onMapAreaOver: MouseEventHandler<HTMLAreaElement> = (e) => {
    const id = e.currentTarget.dataset["area"]

    setHoverState(true)

    document.querySelectorAll(`[data-layer-id]`).forEach((layer) => {
      layer?.classList.remove("show")
    })

    document.querySelector(`[data-layer-id="${id}"]`)?.classList.add("show")

    document.querySelector(`[data-label-id="${id}"]`)?.classList.add("active")

    document.querySelector(`[data-dot-id="${id}"]`)?.classList.add("active")

    document.querySelector("[data-fade]")?.classList.add("show")

    document.querySelectorAll("[data-label-id]").forEach((label) => {
      if (label?.classList.contains("active")) return
      label?.classList.add("hide")
    })
    document.querySelectorAll("[data-dot-id]").forEach((dot) => {
      if (dot?.classList.contains("active")) return
      dot?.classList.add("hide")
    })
  }

  const onMapAreaOut: MouseEventHandler<HTMLAreaElement> = (e) => {
    const id = e.currentTarget.dataset["area"]
    document.querySelector(`[data-layer-id="${id}"]`)?.classList.remove("show")

    document.querySelector(`[data-label-id="${id}"]`)?.classList.remove("active")

    document.querySelector(`[data-dot-id="${id}"]`)?.classList.remove("active")

    document.querySelector("[data-fade]")?.classList.remove("show")

    document.querySelectorAll("[data-label-id]").forEach((label) => {
      label?.classList.remove("hide")
    })
    document.querySelectorAll("[data-dot-id]").forEach((dot) => {
      dot?.classList.remove("hide")
    })
  }

  const onMouseOver: MouseEventHandler<HTMLDivElement> = (e) => {
    const labelId = e.currentTarget.dataset.labelId
    const dotId = e.currentTarget.dataset.dotId

    setHoverState(true)

    document.querySelectorAll("[data-layer-id]").forEach((layer) => {
      layer?.classList.remove("show")
    })

    document.querySelector(`[data-layer-id="${labelId}"]`)?.classList.add("show")
    document.querySelector(`[data-layer-id="${dotId}"]`)?.classList.add("show")

    e.currentTarget.classList.add("active")

    document.querySelector("[data-fade]")?.classList.add("show")

    document.querySelectorAll("[data-label-id]").forEach((label) => {
      if (label?.classList.contains("active")) return
      label?.classList.add("hide")
    })
    document.querySelectorAll("[data-dot-id]").forEach((dot) => {
      if (dot?.classList.contains("active")) return
      dot?.classList.add("hide")
    })
  }

  const onMouseOut: MouseEventHandler<HTMLDivElement> = (e) => {
    const labelId = e.currentTarget.dataset.labelId
    const dotId = e.currentTarget.dataset.dotId
    document.querySelector(`[data-layer-id="${labelId}"]`)?.classList.remove("show")
    document.querySelector(`[data-layer-id="${dotId}"]`)?.classList.remove("show")

    e.currentTarget.classList.remove("active")

    document.querySelector("[data-fade]")?.classList.remove("show")

    document.querySelectorAll("[data-label-id]").forEach((label) => {
      label?.classList.remove("hide")
    })
    document.querySelectorAll("[data-dot-id]").forEach((dot) => {
      dot?.classList.remove("hide")
    })
  }
  const onEnded = (): void => {
    if (mdmin && !playedState) {
      setEndedState(true)
      setPlayedState(true)

      document.querySelector<HTMLDivElement>("[data-map]")?.classList.add("active", "anim")

      setTimeout((): void => {
        document.querySelector<HTMLDivElement>("[data-map]")?.classList.add("anim-end")
        document.querySelector<HTMLDivElement>("[data-map]")?.classList.remove("anim")
      }, 1200)
    }
  }

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //   const img = document.querySelector<HTMLImageElement>("img[usemap]")!
  //   const scale = {
  //     w: img.width / img.naturalWidth,
  //     h: img.height / img.naturalHeight,
  //   } as const
  //
  //   if (coordsState.length === 0) {
  //     const coords: string[] = []
  //
  //     document.querySelectorAll<HTMLAreaElement>("area").forEach((area) => {
  //       coords.push(area.coords.replace(/ *, */g, ",").replace(/ +/g, ","))
  //     })
  //
  //     setCoordsState(coords)
  //   }
  //
  //   document.querySelectorAll<HTMLAreaElement>("area").forEach((area, i) => {
  //     let isWidth = 0
  //
  //     if (coordsState[i] === undefined) {
  //       return
  //     }
  //
  //     area.coords = coordsState[i]
  //       .split(",")
  //       .map((coord) => {
  //         const dimension = 1 === (isWidth = 1 - isWidth) ? "w" : "h"
  //         return Math.floor(Number(coord) * scale[dimension])
  //       })
  //       .join(",")
  //   })
  // }, [width, coordsState])
  //
  useEffect(() => {
    if (mdmin && !playedState) {
      const videoEl = videoElement.current
      const preloaderEl = preloaderElement.current

      if (!videoEl || !preloaderEl) {
        return
      }

      const playVideo = (): void => {
        preloaderEl.removeAttribute("data-state")
        videoEl.play()
      }

      preloaderEl.dataset.state = "show"

      if (videoEl.buffered.length === 1) {
        playVideo()

        return
      }

      const progressVideo = (): void => {
        if (videoEl.buffered.length === 0) {
          return
        }

        const bufferedEnd = videoEl.buffered.end(0)
        const duration = videoEl.duration
        let progressVideo

        if (duration > 0) {
          progressVideo = Math.round((bufferedEnd / duration) * 100)

          if (progressVideo >= 50) {
            playVideo()
          }
        }
      }

      videoEl.addEventListener("progress", progressVideo)

      return () => videoEl.removeEventListener("progress", progressVideo)
    }
  }, [mdmin, playedState])
  //
  useEffect(() => {
    const layers = document.querySelectorAll<HTMLDivElement>("[data-layer-id]")
    const dataMap = document.querySelector<HTMLDivElement>("[data-map]")

    if (!endedState) {
      return
    }

    const glow = setInterval(() => {
      dataMap?.classList.add("glow-start")

      layers?.forEach((el, i) => {
        el.classList.add("glow")

        el.addEventListener("transitionend", () => {
          el.classList.remove("glow")

          if (i === layers.length - 1) {
            setTimeout((): void => {
              dataMap?.classList.remove("glow-start")
            }, 1000)
          }
        })
      })
    }, 8000)

    if (hoverState) {
      clearInterval(glow)
    }

    return () => clearInterval(glow)
  }, [endedState, hoverState])

  return (
    <>
      <div ref={preloaderElement} className={styles.PreloaderWrapper}>
        <div className={styles.PreloaderInner}>
          <img className={styles.Preloader} src={imgPreloader} />
        </div>
      </div>

      <VideoWrapper
        ref={videoElement}
        autoPlay={false}
        className={styles.VideoWrapper}
        loop={false}
        preload="auto"
        src={video}
        videoProps={{ onEnded }}
        isBg
      />

      <div className={styles.NavigationWrapper} data-map>
        {/*<div className={styles.NavigationElement} data-layer-id="P1">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.01") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="P2">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.02") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="P3">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.03") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="P4">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.04") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="P5">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.05") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="P6">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.06") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="P7">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.07") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="P8">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.08") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="C1" />*/}
        {/*<div className={styles.NavigationElement} data-layer-id="C2" />*/}
        {/*<div className={styles.NavigationElement} data-layer-id="C3" />*/}
        {/*<div className={styles.NavigationElement} data-layer-id="C4" />*/}
        {/*<div className={styles.NavigationElement} data-layer-id="C5" />*/}

        {/*<div className={styles.NavigationElement} data-layer-id="O1">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.09") }}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<div className={styles.NavigationElement} data-layer-id="O2">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.10") }}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<div className={styles.NavigationElement} data-layer-id="O3">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.11") }}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={styles.NavigationElement} data-layer-id="O4">*/}
        {/*  <div*/}
        {/*    className={styles.NavigationLabel}*/}
        {/*    dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.12") }}*/}
        {/*  />*/}
        {/*</div>*/}

        <img className={styles.NavigationMap} src={kvMask} useMap="#kvMap" />

        <img className={styles.NavigationFade} src={kvFade} data-fade />

        {/*<map name="kvMap">*/}
        {/*<area*/}
        {/*  coords="1428,590,1344,561,1403,477"*/}
        {/*  data-area="P1"*/}
        {/*  href={`/assets?industry=${industries[5]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1317,441,1390,373,1331,332"*/}
        {/*  data-area="P2"*/}
        {/*  href={`/assets?industry=${industries[2]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1387,240,1410,280,1329,328"*/}
        {/*  data-area="P3"*/}
        {/*  href={`/assets?industry=${industries[6]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1500,298,1489,215,1607,240"*/}
        {/*  data-area="P4"*/}
        {/*  href={`/assets?industry=${industries[3]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1713,305,1790,312,1785,402"*/}
        {/*  data-area="P5"*/}
        {/*  href={`/assets?industry=${industries[7]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1702,402,1731,509,1788,407"*/}
        {/*  data-area="P6"*/}
        {/*  href={`/assets?industry=${industries[1]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1736,518,1815,524,1733,610"*/}
        {/*  data-area="P7"*/}
        {/*  href={`/assets?industry=${industries[4]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1634,597,1620,683,1731,608"*/}
        {/*  data-area="P8"*/}
        {/*  href={`/assets?industry=${industries[0]}`}*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1496,380,1500,350,1589,307,1614,384,1605,416"*/}
        {/*  data-area="C5"*/}
        {/*  href="/investors-and-shareholders/financial"*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1605,423,1623,441,1641,547,1539,520,1521,497"*/}
        {/*  data-area="C2"*/}
        {/*  href="/sustainable-development"*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1516,504,1537,524,1620,542,1616,572,1523,592"*/}
        {/*  data-area="C1"*/}
        {/*  href="/about#mission"*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1512,500,1487,377,1614,418"*/}
        {/*  data-area="C3"*/}
        {/*  href="/assets"*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1510,500,1467,502,1406,470,1448,396,1489,384"*/}
        {/*  data-area="C4"*/}
        {/*  href="/about/investment-strategy"*/}
        {/*  shape="poly"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1240,227,78"*/}
        {/*  data-area="O1"*/}
        {/*  href="/assets/mts "*/}
        {/*  shape="circle"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1180,383,78"*/}
        {/*  data-area="O2"*/}
        {/*  href="/assets/ozon"*/}
        {/*  shape="circle"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1190,548,78"*/}
        {/*  data-area="O3"*/}
        {/*  href="/assets/segezha-group"*/}
        {/*  shape="circle"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*<area*/}
        {/*  coords="1260,690,78"*/}
        {/*  data-area="O4"*/}
        {/*  href="/assets/gruppa-etalon"*/}
        {/*  shape="circle"*/}
        {/*  onMouseOut={onMapAreaOut}*/}
        {/*  onMouseOver={onMapAreaOver}*/}
        {/*/>*/}
        {/*</map>*/}

        {/*<div*/}
        {/*  className={styles.NavigationLabel}*/}
        {/*  data-label-id="C1"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*>*/}
        {/*  <a href="/about#mission" />*/}
        {/*  <span dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.13") }} />*/}
        {/*  <div className={styles.NavigationDot} />*/}
        {/*</div>*/}

        {/*<div*/}
        {/*  className={styles.NavigationLabel}*/}
        {/*  data-label-id="C2"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*>*/}
        {/*  <a href="/sustainable-development" />*/}
        {/*  <span dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.14") }} />*/}
        {/*  <div className={styles.NavigationDot} />*/}
        {/*</div>*/}

        {/*<div*/}
        {/*  className={styles.NavigationLabel}*/}
        {/*  data-label-id="C3"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*>*/}
        {/*  <a href="/assets" />*/}
        {/*  <span dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.15") }} />*/}
        {/*  <div className={styles.NavigationDot} />*/}
        {/*</div>*/}

        {/*<div*/}
        {/*  className={styles.NavigationLabel}*/}
        {/*  data-label-id="C4"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*>*/}
        {/*  <a href="/about/investment-strategy" />*/}
        {/*  <span dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.16") }} />*/}
        {/*  <div className={styles.NavigationDot} />*/}
        {/*</div>*/}

        {/*<div*/}
        {/*  className={styles.NavigationLabel}*/}
        {/*  data-label-id="C5"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*>*/}
        {/*  <a href="/investors-and-shareholders/financial" />*/}
        {/*  <span dangerouslySetInnerHTML={{ __html: t("home.Hero.KV.Title.17") }} />*/}
        {/*  <div className={styles.NavigationDot} />*/}
        {/*</div>*/}

        {/*<div*/}
        {/*  className={styles.NavigationDot}*/}
        {/*  data-dot-id="O1"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*/>*/}

        {/*<div*/}
        {/*  className={styles.NavigationDot}*/}
        {/*  data-dot-id="O2"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*/>*/}

        {/*<div*/}
        {/*  className={styles.NavigationDot}*/}
        {/*  data-dot-id="O3"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*/>*/}
        {/*<div*/}
        {/*  className={styles.NavigationDot}*/}
        {/*  data-dot-id="O4"*/}
        {/*  onMouseOut={onMouseOut}*/}
        {/*  onMouseOver={onMouseOver}*/}
        {/*/>*/}
      </div>
    </>
  )
}
