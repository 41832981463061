import React, { useEffect, useRef } from "react"

import { useApiContext } from "~/api"
import { AnimatedNumbers } from "~/components/AnimatedNumbers/AnimatedNumbers"
import { CheckVisibility } from "~/components/CheckVisibility/CheckVisibility"
import { Heading } from "~/components/Heading/Heading"
import { Raw } from "~/components/raw"
import { Theme } from "~/components/Theme/Theme"

import { KeyVisual } from "./components/KeyVisual/KeyVisual"
import styles from "./index.module.css"

export const Hero: React.FC = () => {
  const ref = useRef(null)
  const { headings = [], facts } = useApiContext("/mainpage/info")

  useEffect(() => {
    const randomHeading = headings[Math.floor(Math.random() * headings.length)]
    const heading = ref.current as HTMLElement | null

    if (!heading) {
      return
    }

    heading.innerHTML = randomHeading.name
  })

  return (
    <Theme className={styles.Wrapper} theme="dark">
      <KeyVisual />

      <CheckVisibility>
        {({ inView }) => (
          <div className={styles.AnimationWrapper}>
            <div className={styles.Inner}>
              <div className="grid grid--col-12">
                <Heading className={styles.Title} like="h1">
                  <div ref={ref} />
                </Heading>
              </div>

              {/*<div className={styles.Quotes}>*/}
              {/*  {facts.map(({ name, percent, profit, currency }, i) => {*/}
              {/*    let percentUp*/}

              {/*    if (percent !== null) {*/}
              {/*      percentUp = percent.search("\\+") !== 0*/}
              {/*    }*/}

              {/*    return (*/}
              {/*      <div key={i} className={styles.Quote}>*/}
              {/*        <Heading className={styles.Name} like="h5">*/}
              {/*          <Raw html={name} />*/}
              {/*        </Heading>*/}

              {/*        <div>*/}
              {/*          {percent !== null && (*/}
              {/*            <div className={percentUp ? styles.PercentDown : styles.PercentUp}>*/}
              {/*              <AnimatedNumbers inView={inView} number={percent} />*/}
              {/*            </div>*/}
              {/*          )}*/}

              {/*          <Heading className={styles.Value} like="h3">*/}
              {/*            <AnimatedNumbers inView={inView} number={profit} />*/}
              {/*          </Heading>*/}

              {/*          <div>{currency}</div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    )*/}
              {/*  })}*/}
              {/*</div>*/}
            </div>
          </div>
        )}
      </CheckVisibility>
    </Theme>
  )
}
