import { Crumb } from "~/components/Breadcrumbs/BreadcrumbsProvider"

export const generateCrumbs = (responseUrl = "", serverName?: string): Crumb[] => {
  const crumbs = responseUrl
    .slice(0, responseUrl.includes("?") ? responseUrl.indexOf("?") : undefined)
    .split("/")
    .filter((q) => q)
  let href = ""

  return crumbs.map((crumbName, i) => {
    href += `/${crumbName}` || ""
    const isLastCrumb = i === crumbs.length - 1

    return {
      name: isLastCrumb && serverName ? serverName : crumbName,
      ...(!isLastCrumb && { href }),
    }
  })
}
