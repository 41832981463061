import useMedia from "use-media"

export const useMediaQuery = () => {
  const xsmin = useMedia({ minWidth: "1px" })
  const xsmax = useMedia({ maxWidth: "767px" })

  const smmin = useMedia({ minWidth: "768px" })
  const smonly = useMedia({ minWidth: "768px", maxWidth: "1023px" })
  const smmax = useMedia({ maxWidth: "1023px" })

  const mdmin = useMedia({ minWidth: "1024px" })
  const mdonly = useMedia({ minWidth: "1024px", maxWidth: "1365px" })
  const mdmax = useMedia({ maxWidth: "1365px" })

  const lgmin = useMedia({ minWidth: "1366px" })

  return { xsmin, xsmax, smmin, smonly, smmax, mdmin, mdonly, mdmax, lgmin }
}
