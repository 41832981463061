import { useEffect, useState } from "react"

export const useWindowSize = (): { width?: number; height?: number } => {
  const hasWindow = typeof window === "object"

  const [windowSize, setWindowSize] = useState({
    width: hasWindow ? window.innerWidth : undefined,
    height: hasWindow ? window.innerHeight : undefined,
  })

  useEffect(() => {
    if (!hasWindow) {
      return
    }

    const handleResize = (): void => {
      setWindowSize({
        width: hasWindow ? window.innerWidth : undefined,
        height: hasWindow ? window.innerHeight : undefined,
      })
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [hasWindow])

  return windowSize
}
