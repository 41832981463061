import cn from "classnames"
import React from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import videoOne from "./assets/video-one.mp4"
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import videoTwo from "./assets/video-two.mp4"
import styles from "./index.module.css"

type Props = {
  videoProps?: JSX.IntrinsicElements["video"]
  className?: string
  src?: string
  loop?: boolean
  autoPlay?: boolean
  preload?: "metadata" | "auto" | "none"
  isBg?: boolean
  defaultVideo?: "videoOne" | "videoTwo"
}

export const VideoWrapper = React.forwardRef<HTMLVideoElement, Props>(
  (
    {
      className,
      src,
      defaultVideo,
      videoProps,
      loop,
      isBg = false,
      autoPlay = true,
      preload = "metadata",
    },
    ref,
  ) => {
    let srcVideo = src

    if (defaultVideo) {
      switch (defaultVideo) {
        case "videoOne":
          srcVideo = videoOne
          break
        case "videoTwo":
          srcVideo = videoTwo
          break
      }
    }

    return (
      <div
        className={cn(className, styles.Wrapper, {
          [styles.Background]: isBg,
        })}
      >
        <video ref={ref} autoPlay={autoPlay} loop={loop} preload={preload} muted {...videoProps}>
          <source src={srcVideo} type="video/mp4;" />
        </video>
      </div>
    )
  },
)
