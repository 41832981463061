import React from "react"
import { useTranslation } from "react-i18next"

import { Layout } from "~/components/Layout/Layout"
import { Programs } from "~/components/Programs/Programs"

import { AssetsFunds } from "./components/AssetsFunds/AssetsFunds"
import { Hero } from "./components/Hero/Hero"
import { Investors } from "./components/Investors/Investors"
import { NewsWrapper } from "./components/NewsWrapper/NewsWrapper"

const Home: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Layout
      description={t("page.home.Description")}
      headerTheme="onDark"
      title={t("page.home.Title")}
      isHome
    >
      <Hero />
      {/*<NewsWrapper />*/}
      {/*<AssetsFunds />*/}
      {/*<Investors />*/}
      {/*<Programs title={t("home.Development.Title")} inHome />*/}
    </Layout>
  )
}

export default Home
