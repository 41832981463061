import { getApiContext, GetInitialPropsType } from "~/api"
import { generateCrumbs } from "~/helpers/generateCrumbs"
import Home from "~/views/home"

const getInitialProps: GetInitialPropsType = async ({ req }) => {
  const apiContext = await getApiContext(
    [
      { url: "/mainpage/info" },
      { url: "/programs/list" },
      { url: "/mainpage/news" },
      { url: "/assets/list" },
      { url: "/fund/list" },
      { url: "/quotes/list" },
      { url: "/calendar/list" },
      { url: "/pressreleases/list", query: { items_per_page: 2, page_number: 1 } },
      { url: "/news/list", query: { items_per_page: 2, page_number: 1 } },
      { url: "/media/list", query: { items_per_page: 2, page_number: 1 } },
      { url: "/ir/list", query: { items_per_page: 1, page_number: 1 } },
    ],
    req ? req.headers.host : location.host,
  )

  const crumbs = generateCrumbs(req?.url)

  return { apiContext, crumbs }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
Home.getInitialProps = getInitialProps

export default Home
